<template>
  <div class="flix-bg-warning flix-form-group flix-text-center">
    <div class="flix-container">
      <h3 class="flix-html-h3">{{ $tc('message.search', 1) }}</h3>

      <form @submit.prevent="setSearch()" class="flix-form-group">
        <div class="flix-input-group flix-input-group-lg">
            <input v-model="newKeyword" type="text" class="flix-form-control" :placeholder="$t('message.pleaseFillOut')">
            <span class="flix-input-group-btn"><button type="submit" class="flix-btn flix-btn-warning">{{ $t('message.search') }}</button></span>
        </div>
      </form>
      <div class="flix-text-info flix-form-group"><flixIcon :id="'search'" /> {{ $tc('message.customer', 2) }} ({{ $tc('message.first_name', 2) }}, {{ $tc('message.last_name', 2) }}, {{ $tc('message.email', 2) }} ...), {{ $tc('message.appointments', 2) }} ({{ $tc('message.title', 2) }}, ...)</div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: { callback: Function, keyword: [String, Number] },
  data () {
    return {
      newKeyword: this.keyword
    }
  },
  methods: {
    setSearch () {
      if (!this.newKeyword.trim()) {
        return false
      }
      this.callback(this.newKeyword)
    }
  }
}
</script>
<style lang="sass" scoped>
  .flix-bg-warning
    padding: 20px
    margin-top: -20px
  .flix-form-control
    text-align: center
</style>
